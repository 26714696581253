import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import Explore from "../components/explore"

const TrainingPage = () => (
  <div id="indexPage">
    <Header/>
    <div className="content-pad">
      <h2>Formazione FishmedNet</h2>
    <p>
      Nell'ambito delle attività realizzate dal progetto FISHMEDNET, la formazione rappresenta uno strumento
      chiave per migliorare le competenze delle aziende e sviluppare nuove opportunità di diversificazione delle
      attività di pesca.
    </p>
    <p>
      Attraverso 4 moduli formativi, ognuno dedicato ad una specifica tematica, gli esperti intervistati delineano
      le criticità e le opportunità delle attività di diversificazione fornendo al contempo la testimonianza di buone
      pratiche già presenti nel contesto mediterraneo.
    </p>
    <p>
      <a href="https://www.youtube.com/@fishmednetenicbcmed/playlists">https://www.youtube.com/@fishmednetenicbcmed/playlists</a>
    </p>

    <h3>Turismo</h3>
    <br/>
    <strong>Dall'Itti-turismo al pesca-turismo, tutti gli step necessari per avviare una nuova attività</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/_ZAXK3eX4os" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h3>Acquacultura</h3>
    <br/>
    <strong>Quali sono le opportunità e le criticità del settore e come avviare e gestire un'attività di acquacoltura?</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/u_lfn-eTAUU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h3>Cibo</h3>
    <br/>
    <strong>Un'introduzione ai principali processi di trasformazione dei prodotti ittici e ai relativi rischi</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Y_D64aLqiME" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h3>Servizi per la Comunità</h3>
    <br/>
    <strong>Una panoramica sui vari servizi ambientali connessi alla diversificazione della pesca</strong>
    <p>
      <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/Ad0QH5kHH4U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </p>

    <h2>Esplora tra le ultime proposte</h2>
    </div>
    <Explore slug="index"/>

    <Footer/>
  </div>
)

export default TrainingPage
